<template>
  <div>
    <el-form ref="resetPassForm" :model="resetPassForm" :rules="resetPassRules" label-position="left" label-width="0px" class="reset-form">
      <el-form-item prop="oldPassword" class="password">
        <el-input v-model="resetPassForm.oldPassword" :type="passType" name="oldPassword" auto-complete="off" placeholder="请输入原密码" />
        <i :class="['iconfont', {'icon-xianshimima': oldPasswordType === 'text', 'icon-password-hide': oldPasswordType==='password'}]" @click="toggleOldPass" />
      </el-form-item>
      <el-form-item prop="password" class="password">
        <el-input v-model="resetPassForm.password" size="small" :type="passType" name="password" auto-complete="off" :placeholder="`请输入新的密码`" />
        <i :class="['iconfont', {'icon-xianshimima': passType==='text', 'icon-password-hide': passType==='password'}]" @click="togglePass" />
      </el-form-item>
      <el-form-item prop="confirmPassword" class="password">
        <el-input v-model="resetPassForm.confirmPassword" size="small" :type="confirmPasswordType" name="confirmPassword" auto-complete="off" placeholder="确认密码" />
        <i :class="['iconfont', {'icon-xianshimima': confirmPasswordType==='text', 'icon-password-hide': confirmPasswordType==='password'}]" @click="toggleConfirmPass" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import PwdRule from "@/mixins/pwd-rule";
import { setPassword } from "@/api/auth";

export default {
  name: "UpdatePasswordDialog",
  mixins: [PwdRule],
  props: {
    data: {
      type: Array,
    },
  },
  data () {
    const validateConfirmPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } if (value !== this.resetPassForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      passType: 'password',
      confirmPasswordType: 'password',
      oldPasswordType: 'password',
      loading: false,
      resetPassForm: {
        oldPassword: '',
        password: '',
        confirmPassword: ''
      },
      resetPassRules: {
        oldPassword: [
          { required: true, message: '密码不能为空' }
        ],
        password: [
          { required: true, message: '密码不能为空' }
        ],
        confirmPassword: [
          { required: true, message: '密码不能为空' },
          { required: true, validator: validateConfirmPass, trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    handleAction ({ action }) {
      console.log(action);
      if (action === "close") {
        this.handleClose();
      } else {
        this.handleSubmit();
      }
    },
    togglePass () {
      this.passType = this.passType === 'text' ? 'password' : 'text';
    },
    toggleOldPass () {
      this.oldPasswordType = this.oldPasswordType === 'text' ? 'password' : 'text';
    },
    toggleConfirmPass () {
      this.confirmPasswordType = this.confirmPasswordType() === 'text' ? 'password' : 'text';
    },
    handleClose () {
      this.$emit("close");
    },
    handleLogout () {
      const token = this.data[0]; //
      let url = process.env.VUE_APP_IFRAME_URL;
      const loginUrl = `${url}/logout?token=${token}&client_id=43d93dab7ebef303`;
      window.location.replace(loginUrl);
    },
    async handleSubmit () {
      if (this.loading) return;
      try {
        await this.$refs.resetPassForm.validate();
        this.loading = true;
        const that = this;
        setPassword(this.resetPassForm).then(() => {
          this.$message.success('重置密码成功');
          that.handleLogout();
          this.$emit("finder-close");
        }).catch(() => {
          this.loading = false;
        });
      } catch (e) {
        console.error(e);
      }
    },
  }
};
</script>

<style scoped>
</style>
